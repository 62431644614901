import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Computer from "@material-ui/icons/Computer";
import SystemUpdate from "@material-ui/icons/Update";
import Star from "@material-ui/icons/Star";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <h2 className={classes.title}>PI2c OS and Hat</h2>
            <h5 className={classes.description}>
              PI2c OS is a free, ready to use custom yocto based linux image for
              the Raspberry Pi. It is equipped with a REST server for gpio, pwm,
              spi, i2c, control and a TCP/Websocket serial interface. It is
              currently available for the Raspberry PI 3 A/B+. The PI2c hat
              allows the module to be enclosed with pluggable connectors,
              provides additional leds, and output voltage control. <br />{" "}
              <strong>
                The PI2c hat and enclosure will be available soon.
              </strong>
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="No Drivers"
                description="No installation necessary. A network is autmatically established when plugged into your computer"
                icon={Computer}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Auto Updates"
                description="OS automatically applies security and application updates on powerup"
                icon={SystemUpdate}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Modern Software"
                description="Communicate with sensors and devices using Go, Python, Rust, Javascript, and more. Create and release applications that run in a browser or docker container"
                icon={Star}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
