import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import SwapVerticalCircle from "@material-ui/icons/SwapVerticalCircle";
import Language from "@material-ui/icons/Language";
import Explore from "@material-ui/icons/Explore";
import Transform from "@material-ui/icons/Transform";
import Extension from "@material-ui/icons/Extension";
import Code from "@material-ui/icons/Code";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Dashboard from "@material-ui/icons/Dashboard";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

//import iphone from "assets/img/sections/iphone.png";
import iphone from "assets/images/pi2cWebImages/image11.png";
import bg9 from "assets/images/wallLights.jpg";

function SectionFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 3 START */}
        <div className={classes.features3}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h2 className={classes.title}>Notable features</h2>
              <InfoArea
                className={classes.infoArea}
                icon={Explore}
                title="Fast and Reliable Discovery"
                description="PI2c OS broadcasts itself over the network using Zeroconf and UDP broadcasting"
                iconColor="primary"
              />
              <InfoArea
                className={classes.infoArea}
                icon={SwapVerticalCircle}
                title="Simple Rest API"
                description="Support one or multiple browser, mobile, or pc clients. Chain instructions and save for later execution"
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <InfoArea
                className={classes.infoArea}
                icon={Transform}
                title="Serial to network adapter"
                description="Use PI2c to create a low cost bridge where serial communication will be directed over TCP or Websockets"
                iconColor="primary"
              />
              <InfoArea
                className={classes.infoArea}
                icon={Extension}
                title="Hat and enclosure"
                description="Small portable pluggable module with Aardvark compatible connector and led's"
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 3 END */}
      </div>
      {/* Feature 5 START */}
      <div
        className={classes.features5}
        style={{ backgroundImage: `url(${bg9})` }}
      >
        <GridContainer>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Code}
                  title="For Developers"
                  description={
                    <p>
                      PI2c allows you to use the tools you like on the platforms
                      of your choice.
                    </p>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={FormatPaint}
                  title="For Hobbyists"
                  description={
                    <a href="https://docs.pi2c.concerco.cloud">
                      <p> PI2c OS is free to use and simple to get started. </p>
                    </a>
                  }
                  iconColor="danger"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Dashboard}
                  title="Multipurpose"
                  description={
                    <p>
                      PI2c allows one Pi to handle multiple applications and
                      operations without having to change any code on the device
                    </p>
                  }
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={ViewCarousel}
                  title="Examples Available"
                  description={
                    <a href="https://docs.pi2c.concerco.cloud">
                      <p>Please see our documentation and examples for help</p>
                    </a>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={AccessTime}
                  title="Save Time"
                  description={
                    <p>
                      Focus on your task and application without having to worry
                      about low level communication, kernel drivers, and
                      maintaining updates.
                    </p>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={AttachMoney}
                  title="Save Money"
                  description={
                    <a href="https://docs.pi2c.concerco.cloud">
                      <p>
                        PI2c software is free and the hardware required is low
                        cost. Try now!
                      </p>
                    </a>
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
      </div>
      {/* Feature 5 END */}
    </div>
  );
}

export default withStyles(featuresStyle)(SectionFeatures);
