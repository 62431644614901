import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// core components
import Button from "components/CustomButtons/Button.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import sectionsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/sectionsStyle.jsx";

class SectionSections extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem
            md={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <div className={classes.sectionDescription}>
              <h2 className={classes.title}>Try now</h2>
              <h5 className={classes.description}>
                Click the "Documentation" link or the button below to 
                download the OS and get started. Links to the open 
                source applications can also be found in the docs.
              </h5>
              <a href="https://docs.pi2c.concerco.cloud">
                <Button
                  color="rose"
                  target="_blank"
                  className={classes.navButton}
                  round
                >
                  Setup
                </Button>
              </a>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(sectionsStyle)(SectionSections);
